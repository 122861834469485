<template>
  <div class="template-list">
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardBody>
            <CTabs>
              <CTab title="Свойства" v-if="equipmentDetail">
                <CRow class="mt-40">
                  <CCol sm="12" v-if="!$route.params.code">
                    <h3>
                      <strong>Создание запчасти</strong>
                    </h3>
                    <br />
                    <p>
                      Здесь добавляются только запчасти для требования на
                      производство/склад (ТС), которые не имеют отдельной
                      стоимости. <br />
                      Запчасти со стоимостью, добавляются из 3D моделей
                      комплексов через другие настройки.
                    </p>
                  </CCol>
                  <CCol sm="12" v-if="$route.params.code">
                    <h3>
                      <strong>Редактирование запчасти</strong>
                    </h3>
                    <br />
                    <p>
                      Сохранение изменений происходит автоматически при
                      изменении данных в форме<br />
                    </p>
                  </CCol>
                </CRow>

                <CForm @submit.prevent="handleSubmit">
                  <CRow class="mt-20 mb-30">
                    <CCol sm="4">
                      <h4>Основные</h4>
                    </CCol>
                  </CRow>
                  <CRow class="mt-20">
                    <CCol sm="4">
                      <label >Архивная</label>
                    </CCol>
                    <CCol sm="6">
                      <input
                        type="checkbox"
                        
                        v-model="equipmentDetail.archive"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Код"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                        v-model="equipmentDetail.code"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Описание"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                        v-model="equipmentDetail.description"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="$route.params.code">
                    <CCol sm="12">
                      <CInput
                        label="Цена"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                        v-model="equipmentDetail.price"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="$route.params.code">
                    <CCol sm="12">
                      <CInput
                        label="Цена c оцинковкой"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                        v-model="equipmentDetail.price_zink"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="$route.params.code">
                    <CCol sm="12">
                      <CInput
                        label="Вес, кг"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                        v-model="equipmentDetail.weight"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow class="mb-30">
                    <CCol sm="4">
                      <label>Только для ТС</label>
                    </CCol>
                    <CCol sm="6">
                      <input
                        type="checkbox"
                        v-model="equipmentDetail.virtual"
                        @input="requestEquipmentDetailUpdate"
                      />
                    </CCol>
                  </CRow>
                  <CRow class="mb-30">
                    <CCol sm="12">
                      <h4>Упаковка</h4>
                      <br />
                    <p><b>Гофролист</b> указывается в <b>мм</b>.</p>
                    <p>Гофрокороб используется только для хомутов.</p>
                    </CCol>
                  </CRow>
                  <div v-if="equipmentDetail">
                  <H1></H1>
                  <CRow  v-for="equipmentPackage in equipmentDetail.equipment_packages"
                      :key="equipmentPackage.equipment_package_id">
                      <CCol sm="12">
                        <CInput
                          :label="getEquipmentPackageDescription(equipmentPackage)"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          v-model="equipmentPackage.amount_per_detail"
                          @input="requestEquipmentDetailPackageUpdate(equipmentPackage)"
                        />
                      </CCol>
            
                  </CRow>
                  </div>
                  <!-- <CCol sm="12">
                  <div role="group" class="form-group">
                    <div>Цвет</div>
                    <v-select class="v-select" style="width: 100%" label="ral_code" :reduce="color => color.id" v-model="equipmentDetail.color"
                      @input="requestEquipmentDetailUpdate" :options="colors" placeholder="" :clearSearchOnSelect="true"
                      :closeOnSelect="true">
                      <template style="width: 100%" #option="{ ral_code, hex_code }">
                        <div class="list-item-container" style="border-top: 1px solid #d8dbe0; display: flex">
                          <div style="width: 70px">
                            <span>{{ ral_code }}</span>
                          </div>
                          <div :style="{
                            width: '30px',
                            height: '30px',
                            backgroundColor: `#${hex_code}`,
                          }"></div>
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div role="group" class="form-group"></div>
                </CCol> -->
                  <!-- <CCol sm="12">
                  <div role="group" class="form-group">
                    <div>Цвет с оцинковкой</div>
                    <v-select class="v-select" style="width: 100%" label="ral_code" :reduce="color => color.id" v-model="equipmentDetail.color_zink"
                      @input="requestEquipmentDetailUpdate" :options="colors" placeholder="" :clearSearchOnSelect="true"
                      :closeOnSelect="true">
                      <template style="width: 100%" #option="{ ral_code, hex_code }">
                        <div class="list-item-container" style="border-top: 1px solid #d8dbe0; display: flex">
                          <div style="width: 70px">
                            <span>{{ ral_code }}</span>
                          </div>
                          <div :style="{
                            width: '30px',
                            height: '30px',
                            backgroundColor: `#${hex_code}`,
                          }"></div>
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div role="group" class="form-group"></div>
                </CCol> -->

                  <br />
                  <br />
                  <CRow>
                    <CCol>
                      <div
                        class="
                          d-grid
                          gap-2
                          d-md-flex
                          justify-content-md-between
                        "
                      >
                        <CButton color="dark" type="submit" @click="handleBack">
                          Назад
                        </CButton>
                        <CButton
                          v-if="!equipmentDetail.new"
                          color="danger"
                          type="submit"
                          @click="handleDelete"
                        >
                          Удалить
                        </CButton>
                        <CButton
                          v-if="equipmentDetail.new"
                          color="success"
                          type="submit"
                          @click="handleBack"
                        >
                          Создать
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CForm>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="8">
        <div id="canvas" style="height: 800px"></div>
      </CCol>
    </CRow>
  </div>
</template>

<style>
html,
body {
  margin: 0;
}

#canvas {
  display: block;
  min-width: 500px;
  min-height: 75vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

#gui {
  position: absolute;
  top: 0;
  right: 0;
}


</style>


<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import vSelect from "vue-select";

export default {
  name: "EqipmentDetail",
  data() {
    return {
      renderer: null,
      raycaster: null,
      gltfLoader: null,
      scene: null,
      gui: null,
      camera: null,
      flatCamera: null,
      threeCamera: null,
      flatCameraOn: false,
      light: null,
      equipmentDetail: null,
      equipmentPackage: null,
      equipmentPackages: [],
      equipmentDetailEquipmentPackage: null,
      plane: null,
      el: null,
      controls: null,
      threeControls: null,
      enableControls: true,
      dragControls: null,
      mouse: { x: 0, y: 0 },
      intersections: [],
      guiBoxModel: null,
      lightFolder: null,
      intersectionBoxes: [],
      sceneObjects: [],
      intersectedBoxes: [],
      dragControlsList: [],
      threeDragControlsList: [],
      rotatingObjectId: null,
      unsubscribe: null,
      directionalLight: null,
      animationFrameId: null,
      equipmentDetailMeta: null,
      code: null,
      description: "",
      price: null,
      priceZink: null,
      weight: null,
      colorCode: null,
      virtual: false,
      zinkColorCode: null,
      deleted: false,
    };
  },
  components: { vSelect },

  computed: {
    ...mapState({
      equipmentDetails: (state) => state.equipmentDetails.all,
      colors: (state) => state.dictionaryColors.all,
    }),
  },
  created() {
    this["dictionaryColors/getAll"]();
    this["equipmentPackages/getAll"]();
    if (this.$route.params.code) {
      this["equipmentDetails/getAll"]();
    } else {
      this.equipmentDetail = {
        new: true,
        code: "",
        description: "",
        price: 0,
        priceZink: 0,
        virtual: true,
        length: 0,
        height: 0,
        width: 0,
        weight: 0,
        deleted: false,
        equipment_packages: []
      };
    }
  },
  methods: {
    ...mapActions([
      "equipmentDetails/getAll",
      "equipmentPackages/getAll",
      "dictionaryColors/getAll",
      "equipmentDetails/update",
      "equipmentDetails/create",
      "equipmentDetails/getById",
      "equipmentDetails/updateEquipmentDetailEquipmentPackage"
    ]),
    prepare: function () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "equipmentDetails/getAllSuccess") {
          console.log(mutation.type)
          for (const equipmentDetail of mutation.payload) {
            if (equipmentDetail.code === this.$route.params.code && equipmentDetail.version === parseInt(this.$route.params.version)) {
              this.equipmentDetail = equipmentDetail;
              console.log(this.equipmentDetail)
              break;
            }
          }
        }
        if (mutation.type === "equipmentDetails/createSuccess") {
          this.equipmentDetail.new = false;
          this.equipmentDetail.id = mutation.payload.id;
        }
        if (mutation.type === "equipmentPackages/getAllSuccess") {
          this.equipmentPackages = mutation.payload;
        }
      });
    },

    getPackageAmountPerDetail(equipmentPackage) {
      if (!this.equipmentDetail) {
        return 0;
      }
      for (const testEquipmentPackage of this.equipmentDetail.equipment_packages) {
        if (equipmentPackage.id === testEquipmentPackage.equipment_package_id) {
          return testEquipmentPackage.amount_per_detail
        }
      }
      return 0;
    },

    getEquipmentPackageDescription(equipmentPackage) {
      if (!this.equipmentDetail) {
        return 0;
      }
      for (const testEquipmentPackage of this.equipmentPackages) {
        if (equipmentPackage.equipment_package_id === testEquipmentPackage.id) {
          return testEquipmentPackage.description
        }
      }
      return '';
    },

    handleBack() {
      history.back();
    },

    handleDelete() {
      this.equipmentDetail.archive = true;
      this.handleBack();
    },

    prepareSubmitData() {
      return {
        id: this.equipmentDetail.id,
        code: this.equipmentDetail.code,
        archive: this.equipmentDetail.archive,
        price: this.equipmentDetail.price,
        price_zink: this.equipmentDetail.price_zink,
        description: this.equipmentDetail.description,
        color: this.equipmentDetail.color,
        color_zink: this.equipmentDetail.color_zink,
        virtual: this.equipmentDetail.virtual,
        length: this.equipmentDetail.length,
        height: this.equipmentDetail.height,
        width: this.equipmentDetail.width,
        weight: this.equipmentDetail.weight,
        deleted: this.equipmentDetail.archive,
      };
    },

    handleSubmit() {
      const formData = this.prepareSubmitData();
      if (this.equipmentDetail.new) {
        this["equipmentDetails/create"](formData);
      } else {
        this["equipmentDetails/update"](formData);
      }
    },

    init: function () {},

    requestEquipmentDetailUpdate: debounce(function () {
      this.updateEquipmentDetail();
    }, 1000),

    updateEquipmentDetail() {
      if (this.equipmentDetail.new) {
        return;
      }
      const formData = this.prepareSubmitData();
      this["equipmentDetails/update"](formData);
    },

    requestEquipmentDetailPackageUpdate: debounce(function (equipmentPackage) {
      this.updateEquipmentDetailPackage(equipmentPackage);
    }, 1000),

    updateEquipmentDetailPackage(equipmentPackage) {
      const formData = {...equipmentPackage, equipment_detail_id: this.equipmentDetail.id};
      this["equipmentDetails/updateEquipmentDetailEquipmentPackage"](formData);
    },

    initPlane: function (planeWidth, planeHeight) {
      const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
      const planeMaterial = new THREE.MeshStandardMaterial({
        color: 0x3fb27f,
        side: THREE.DoubleSide,
      });

      this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
      this.plane.rotation.x = Math.PI / 2;
      this.scene.add(this.plane);
    },

    initGui: function () {},

    checkIntersections: function (helper, boxes) {
      const box = helper.box;
      for (let boxHelperToCheck of boxes) {
        const boxToCheck = boxHelperToCheck.box;
        if (!box.equals(boxToCheck)) {
          const intersects = box.intersectsBox(boxToCheck);
          if (intersects) {
            //
            boxHelperToCheck.material.color = new THREE.Color(0xff0000);
            if (!this.intersectedBoxes.includes(box)) {
              this.intersectedBoxes.push(box);
            }
            if (!this.intersectedBoxes.includes(boxToCheck)) {
              this.intersectedBoxes.push(boxToCheck);
            }
          } else {
            helper.material.color = new THREE.Color(0xffffff);
            boxHelperToCheck.material.color = new THREE.Color(0xffffff);
            //   if (!this.intersectedBoxes.includes(box)) {
            //     this.intersectedBoxes.push(box);
            //   }
            //   if (!this.intersectedBoxes.includes(boxToCheck)) {
            //     this.intersectedBoxes.push(boxToCheck);
            //   }
          }
        }
      }
    },

    switchCameraMode: function (flatCameraOn) {
      this.camera = flatCameraOn ? this.flatCamera : this.threeCamera;

      this.controls.enabled = flatCameraOn && this.enableControls;
      this.threeControls.enabled = !flatCameraOn && this.enableControls;

      for (let dragControls of this.dragControlsList) {
        dragControls.enabled = flatCameraOn;
      }

      for (let dragControls of this.threeDragControlsList) {
        // dragControls.transformGroup = flatCameraOn;
        dragControls.enabled = !flatCameraOn;
      }

      this.controls.update();
      this.threeControls.update();

      for (let helper of this.intersectionBoxes) {
        helper.visible = flatCameraOn;
      }

      this.camera.updateProjectionMatrix();
    },

    raycast: function (event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.mouse.x = (x / this.viewportWidth) * 2 - 1;
      this.mouse.y = -(y / this.viewportHeight) * 2 + 1;
    },

    animate: function () {
      this.animationFrameId = requestAnimationFrame(this.animate);
      this.controls.update();

      this.raycaster.setFromCamera(this.mouse, this.camera);

      this.raycaster.linePrecision = 0.1;
      if (this.equipmentDetail) {
        //this.intersections = this.raycaster.intersectObject(this.models);
      }

      this.renderer.render(this.scene, this.camera);
    },

    clear: function () {
      if (!this.content) return;
      this.scene.remove(this.content);

      // dispose geometry
      this.content.traverse((node) => {
        if (!node.isMesh) return;
        node.geometry.dispose();
      });
    },

    changeModel: function (model) {
      var manager = new THREE.LoadingManager();
      manager.onProgress = function (item, loaded, total) {};

      var onProgress = function (xhr) {
        if (xhr.lengthComputable) {
          var percentComplete = (xhr.loaded / xhr.total) * 100;
        }
      };

      var onError = function (xhr) {};
      if (!this.gltfLoader) {
        return;
      }

      this.gltfLoader.load(
        model.model_constructor,
        (gltf) => {
          const scene = gltf.scene || gltf.scenes[0];
          const clips = gltf.animations || [];
          this.setContent(scene, clips, model);
        },
        onProgress,
        onError
      );
    },

    setContent(object, clips, model) {
      var ref = this;

      if (model.x || model.z) {
        object.children[0].position.x = model.x;
        object.children[0].position.z = model.z;
      }

      this.content = object;

      const group = new THREE.Group();

      group.add(object);

      this.scene.add(group);

      // Object borders helper
      const bordersHelper = new THREE.Box3().setFromObject(object);

      const helperSize = new THREE.Vector3();
      bordersHelper.getSize(helperSize);
      helperSize.addScalar(1.5); // прибавляем полтора метра к размерам объекта

      const centerVector = new THREE.Vector3();
      bordersHelper.getCenter(centerVector);

      bordersHelper.setFromCenterAndSize(centerVector, helperSize);
      const helper = new THREE.Box3Helper(bordersHelper, 0xffffff);

      group.add(helper);
      helper.visible = this.flatCameraOn;

      // добавим helper в массив коробок для проверки пересечений
      this.intersectionBoxes.push(helper);

      // добавим группу в масив всех объектов
      this.sceneObjects.push(group);
      //const length = this.sceneObjects.length;

      //   this.guiBoxModel = this.gui.addFolder(`${model.code} ${length}`);
      //   this.guiBoxModel
      //     .add(group.rotation, "y", 0, Math.PI, (Math.PI * 45) / 180)
      //     .name("угол поворота");

      // Управление объектом в 2D-камере

      const dragControls = new DragControls(
        [group],
        this.flatCamera,
        this.renderer.domElement
      );

      dragControls.enabled = this.flatCameraOn;
      dragControls.transformGroup = true;
      this.dragControlsList.push(dragControls);

      dragControls.addEventListener("dragstart", (event) => {
        event.object.position.y = 0;
      });

      dragControls.addEventListener("drag", (event) => {
        event.object.position.y = 0;
      });

      dragControls.addEventListener("dragend", (event) => {
        event.object.position.y = 0;
        ref.checkIntersections(helper, ref.intersectionBoxes);
      });

      // Управление объектом в 3D-камере

      const threeDragControls = new DragControls(
        [object],
        this.threeCamera,
        this.renderer.domElement
      );

      this.threeDragControlsList.push(threeDragControls);
      threeDragControls.transformGroup = true;
      threeDragControls.enabled = !this.flatCameraOn;
      //   this.guiBoxModel.add(threeDragControls, "transformGroup").name("группа");

      // Активация вращения объекта
      // var obj = {
      //   add: function () {
      //     ref.rotatingObjectId = length - 1;
      //   },
      // };

      // this.guiBoxModel.add(obj, "add");
    },

    wheel: function (ev) {
      // if (ev.deltaY < 0) {
      //   this.pos = "вверх";
      // } else {
      //   this.pos = "вниз";
      // }
    },
  },

  watch: {
    equipmentDetails: function (val, oldVal) {
      if (val) {
        (this.code = val.code),
          (this.description = val.description),
          (this.price = val.price),
          (this.weight = val.weight),
          (this.price_zink = val.price_zink),
          (this.virtual = val.virtual),
          (this.archive = val.archive);
      }
    },
  },

  mounted() {
    this.prepare();
  },
  destroyed() {
    cancelAnimationFrame(this.animationFrameId);
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>
